<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Detalles provincia
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editProvince">
        <b-form
            class="mt-2"
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <validation-provider
                  #default="{ errors }"
                  :name="'Nombre'"
                  rules="required"
              >
                <b-form-group
                    :label="'Nombre'"
                    label-for="province-name"
                >
                  <b-form-input
                      v-model="province.name"
                      name="name"
                      :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Precio 1000'"
                label-for="province-price"
              >
                <b-form-input
                  v-model="province.price_1000"
                  name="price"
                  type="number"
                  :placeholder="'Precio'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Precio 2000'"
                label-for="province-price"
              >
                <b-form-input
                  v-model="province.price_2000"
                  name="price"
                  type="number"
                  :placeholder="'Precio'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Precio 3000'"
                label-for="province-price"
              >
                <b-form-input
                  v-model="province.price_3000"
                  name="price"
                  type="number"
                  :placeholder="'Precio'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Precio 4000'"
                label-for="province-price"
              >
                <b-form-input
                  v-model="province.price_4000"
                  name="price"
                  type="number"
                  :placeholder="'Precio'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="'Precio 5000'"
                label-for="province-price"
              >
                <b-form-input
                  v-model="province.price_5000"
                  name="price"
                  type="number"
                  :placeholder="'Precio'"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Enviar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      paymentPrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      province: 'provinces/getProvince',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'provinces/edit',
      getProvince: 'provinces/getProvince',
    }),
    handleSubmit() {
      this.$refs.editProvince.validate().then(success => {
        if (success) {
          const { id } = this.province
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, province: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.province.name)
      data.append('price_1000', this.province.price_1000)
      data.append('price_2000', this.province.price_2000)
      data.append('price_3000', this.province.price_3000)
      data.append('price_4000', this.province.price_4000)
      data.append('price_5000', this.province.price_5000)

      return data
    },
  },
  async created() {
    await this.getProvince(this.$route.params.id)
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
